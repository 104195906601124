/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}


@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');


@font-face {
  font-family: 'Monument Extended';
  src: url('../public/fonts/MonumentExtended-FreeForPersonalUse/MonumentExtended-Regular.otf') format('regular');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik_OVERVIEW';
  src: url('../public/fonts/fonnts.com-Aeonik_OVERVIEW_Medium.ttf') format('medium');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Porlane';
  src: url('../public/fonts/porlane/Porlane-Regular.eot');
  src: url('../public/fonts/porlane/Porlane-Regular.eot?#iefix') format('embedded-opentype'),
       url('../public/fonts/porlane/Porlane-Regular.woff2') format('woff2'),
       url('../public/fonts/porlane/Porlane-Regular.woff') format('woff'),
       url('../public/fonts/porlane/Porlane-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



/* Panchang - Example with local fonts */

@font-face {
  font-family: 'Panchang';
  src: url('../public/fonts/Panchang_Complete/Fonts/OTF/Panchang-Extrabold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Panchang';
  src: url('../public/fonts/Panchang_Complete/Fonts/OTF/Panchang-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Panchang';
  src: url('../public/fonts/Panchang_Complete/Fonts/OTF/Panchang-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Panchang';
  src: url('../public/fonts/Panchang_Complete/Fonts/OTF/Panchang-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Panchang';
  src: url('../public/fonts/Panchang_Complete/Fonts/OTF/Panchang-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Panchang';
  src: url('../public/fonts/Panchang_Complete/Fonts/OTF/Panchang-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Comico';
  src: url('../public/fonts/Comico_Complete/Fonts/OTF/Comico-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --radius-circle: 50%;
--color-white: #fff;
--transition-3: 1s ease;
--color-mood: rgba(237, 232, 228, 1);
--color-header: #222222;
--color-background: #111111;
--color-border: #494848;
--color-background-2:#ededed;
--text-color-light: hsla(0, 3%, 10%, 0.9); /* Dark text for light mode */
--text-color-dark: hsla(0, 3%, 92%, 0.24);
--text-color: var(--text-color-dark);
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  color:var(--color-white) !important;
  transition: background-color 1s ease-in-out, color 1s ease-in-out;
  background-color: var(--color-background) !important;
}



[data-reveal] {
  transform: translateY(40px);
  opacity: 0;
  transition: var(--transition-3);
}

[data-reveal="left"] { transform: translate(-50px, 0); }

[data-reveal="right"] { transform: translate(50px, 0); }

[data-reveal].revealed {
  transform: translate(0, 0);
  opacity: 1;
}


img {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

  /************Preloader ************/


  .loader-container {
    font-family: 'Teko',sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #181818;
  }
  
  .pre-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  
  .loader-x {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #181818;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader-content {
    position: relative; 
    display: flex;
    width: 400px;
    z-index: 2;
    color: #fff;
    flex-direction: row; 
  }
  
  .count {
    flex: 2;
    text-align: right;
    line-height: 1;
    padding: 0 1em;
  }
  
  .copy {
    flex: 6;
    font-family: "PP Editorial Old";
    font-size: 30px;
    text-transform: uppercase;
    line-height: 1;
  }
  
  .ml16 {
    overflow: hidden;
    font-family: 'Teko',sans-serif;
    font-size: 40px;
  }
  
  .ml16 .letter {
    display: inline-block;
    line-height: 1em;
  }
  
  .loader-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(217, 217, 217, 0.1);
    z-index: -1;
  }
  
  

  /************Scroll Progress ************/
  @-webkit-keyframes border-transform {
    0%, 100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; }
    14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; }
    28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; }
    42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; }
    56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; }
    70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; }
    84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; }
  }
  
  .progress-wrap {
    position: fixed;
    right: 40px;
    bottom: 40px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  
  .progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  .progress-wrap svg path {
    fill: none;
  }
  
  .progress-wrap svg.progress-circle .progress-path {
    stroke: #ecedf3;
    stroke-width: 4;
    box-sizing: border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  
  .arrow-up-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
 
    border-radius: 50%;
    overflow: hidden; /* Ensures the overflow is hidden when the circle expands */
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  
  .arrow-up {
    color: #ecedf3 !important;
    font-size: 24px;
    transition: color 200ms linear;
  }
  
  .arrow-up:hover {
    color: #000 !important;
  }
  
  .arrow-up::before {
    content: "";
 
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .arrow-up-container:hover .arrow-up::before {
    transform: scale(1);
  }
  
  
  
  /**
   * CUSTOM CURSOR
   */

   .cursor-dot,
   .cursor-outline {
     position: fixed;
     top: 0;
     left: 0;
     transform: translate(-50%, -50%);
     border-radius: 50%;
     z-index: 1000;
     pointer-events: none; /* Allow pointer events to pass through */
   }
   
   .cursor-dot {
     height: 10px;
     width: 10px;
     background-color: hsla(0, 0%, 100%, 1); /* Adjust the color to your preference */
     transition: transform 0.2s ease;
   }
   
   .cursor-outline {
     width: 30px;
     height: 30px;
     border: 2px solid hsla(0, 0%, 100%, 0.5);
     transition: transform 0.2s ease;
     
   }
   
   
   .mood-container button {
    cursor: pointer;
   }

   /* Hover effect */
   .cursor-dot.hover {
     transform: translate(-50%, -50%) scale(1.5); /* Change scale as needed */
   }
   
   .cursor-outline.hover {
     transform: translate(-50%, -50%) scale(1.5); /* Change scale as needed */
   }
   


h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

.logo-img{
  width: 400px;
  height: 400px;
}

.logo-icon{
  width: 40px;
  height: 40px;
}

.logo-icon-2{
  width: 45px;
  height: 45px;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}



@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}

/************ Loader Css ************/
.contact-element a {
 text-decoration: none;
 color:var(--color-white);

}

.contact-element a:hover {

  color:var(--color-white);

}


.loader{
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.loader p{
  color: #737373;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 200px;
  animation: text 3s ease-in-out linear;
}

@keyframes text{

  50%{
    color: black;
  }

}


.logo{
  font-family: 'Panchang', sans-serif;
  font-size: 13px;
  font-weight: 700;
}



.scrolling-text-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
  height: 320px;
  display: flex;
}


.scrolling-text {
  display: inline-block;
  white-space: nowrap;
   height: max-content;
   align-items: center;
   
}

.scrolling-text span {
  padding: 0 2rem;
  font-size: 250px;
  font-family: 'Teko', sans-serif;
}


.intro-container {
  height: max-content;
  padding: 0px 25px;

}



/*--------*****************----------*/
/************ NAVBAR CSS ************/
/*-------**********************-----*/

.header-wrapper {
  background-color: transparent !important;
  position: absolute !important;
  top: 0;
 
}


.header-container{
  display: flex;
  padding: 0px 25px;
  align-items: center;
  width: 100% ;
  justify-content: space-between;
}

.logo-container-x{
width: 30% !important;
}


.mood-container button {
  border-radius: 20px;
  padding: 0px 20px;
  height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
  border: 1px solid var(--color-border);
  font-weight: 500;
}

.contact-element {
  width: 30%;
  align-items: center;
  display: flex;
  justify-content: end;
}

.contact-element b {
  font-size: 13px;
  text-transform: uppercase;
  font-family: 'Panchang', sans-serif;

}

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  transition:  0.32s ease-in-out;
  background-color: #121212;
  font-family: 'Teko',sans-serif;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img,
.social-icon a:hover .social-icon {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.social-icon {
  margin: 0px;
  color: white;
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}


.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

.navbar-text button:focus {
  color: #fff; 
  border: 2px solid #fff; 
  outline: none; 
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: grey;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}




/*--------*****************----------*/
/************BANNER CSS ************/
/*-------**********************-----*/


.banner {
  height: 100vh;
  position: relative;
  padding-top: 50px !important;

}

.cover-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 18%;
  align-items: center;
}

.year {
  width: 15%;
  justify-content: space-around;
  display: flex;
}

.cover-center {
  text-align: center;
}

.cover-center h1 {
  font-size: 67px;
  text-transform: uppercase;
  font-family: 'Panchang', sans-serif;
  font-weight: 700;
}


.banner-bottom {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

}

.social-links-container {
  width: 25%;
  align-items: last baseline;
  display: flex;
  padding: 10px;
  justify-content: space-between;
}

.social-link {
  text-decoration: none;
  color: var(--color-white);
}

.social-link:hover {
  color: rgb(136, 135, 135);
}


.name-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: end;
}

.name-container div {
  display: flex;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
  
}

.last-banner {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



.last-banner-2 {
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: space-between;
}

.last-banner div,
.last-banner-2 div {
 display: flex;
 flex-direction: column;
}

.last-banner b ,
.last-banner-2 b{
  font-size: 30px;
  border-bottom: 1px solid var(--color-border);
}

.last-banner b:nth-child(1),
.last-banner-2 b:nth-child(1) {
  border-top: 1px solid var(--color-border);

}

.last-banner span,
.last-banner-2 {
  font-weight: 500;
}


.mask {
  position: relative;
  overflow: hidden;
  display: inline-flex;
}

.mask > span {
  margin-right: 8px; 
}


/*--------*****************----------*/
/************ABOUT SECTION ************/
/*-------**********************-----*/

.about-desc-1 span,
.about-element span {
  color: var(--text-color);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
  animation: scroll-reveal 1s linear forwards;
  -webkit-animation: scroll-reveal 1s linear forwards;
  animation-timeline: view(y);
}

.about-container h1 span {
  animation-range-start: cover 15vh;
  animation-range-end: cover 25vh;
}

.about-desc-1 p span {
  animation-range-start: cover 24vh;
  animation-range-end: cover 55vh;
}

.about-element span {
  animation-range-start: cover 52vh;
  animation-range-end: cover 85vh;
}

@keyframes scroll-reveal {
  to {
    background-size: 100% 100%;
  }
}

@-webkit-keyframes scroll-reveal {
  to {
    background-size: 100% 100%;
  }
}


.about-container {
  height: 100vh;
  padding: 100px 10px;
  display: flex;
  flex-direction: column;
  gap: 50px;

}

.about-container h1 {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Comic Neue', sans-serif;

}

.about-container p {
  font-size: 35px;
  font-weight: 200;
  font-family: 'Oswald', sans-serif;
 position: relative;
}

.about-img-container img {
  width: 50px;
  height: 50px;
  animation: rotate 10s linear infinite;

}


@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}


.about-desc-1 h1 {
margin-bottom: 30px;
font-family: 'Panchang', sans-serif;

}

.about-desc-2 {
  justify-content: space-around;
  display: flex;
  width: 100%;
}

.about-desc-2 p {
  width: 100%;
  font-size: 22px;
  position: relative;
  font-weight: 200;
  font-family: 'Oswald', sans-serif;

}

.services-element {
  width: 40%;
}

.services-element h1 {
  font-weight: 600 ;
  font-family: 'Panchang', sans-serif;

}

.services-element ul {
 padding: 0px;
}

.services-element ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 38px;
  border-bottom: 1px solid #494848;
  width: 60%;
  font-family: 'Panchang', sans-serif;
  font-size: 12px;
}

.about-element {
  width: 40%;
}



/************----------------************/
/***************PROJECT CSS************/
/************---------------- ************/


.horizontal-wrapper {
  position: relative;
  width: 100%;
  height: 300vh;
  background-image: ur;
}

.horizontal-container {
  position: sticky;
  top: 0;
  width: calc(90vw * 4); /* Adjust to fit your content */
  height: 100vh;
  display: flex;
}

.horizontal-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.horizontal-container section {
  position: relative;
  width: 100vw;
  height: 100%;
  padding: 35px 10px 0px;
  display: flex;
  gap: 2em;
  overflow: hidden;
}



.horizontal-container h1 {
  width: 50%;
  font-family: 'Panchang', sans-serif;
  font-weight: 700;
  font-size: 70px ;
   text-transform: uppercase;
}

.project-content h1 {
  width: 50%;
  font-family: 'Comico', sans-serif;
  font-weight: 400;
  font-size: 40px ;
}

.horizontal-container p {
  width: 40%;
  font-family: "PP Neue Montreal";
  font-weight: 400;
  font-size: 16px;
}



#section-2{
  flex-grow: 1;
}



.img-1,
.img-5,
.img8 {
  flex: 2;
}


#section-1 {
  display: flex;
  justify-content: center !important;
  align-items: center;
  position: relative;
}

.projects-header {
  position: absolute;
  top: 30%;
  

}

.arrow-container {
  position: absolute;
  bottom: 10%;
}

.arrow-container span {
  font-size: 40px;
  display: flex;
  align-items: center;
}

.projects-header div:nth-child(1) {
  display: flex;
  align-items: start;
  gap: 5px;
}


.element-2 {
  display: flex;
  align-items: center;
  justify-content: center;
 text-align: center;
}


.element-2 b {
  text-transform: uppercase;
  font-weight: 200;
  font-family: 'Panchang', sans-serif;

}




.element-s {
  height: 100%;
  position: absolute;
  left: -110px;
  top: 20px;
  text-transform: uppercase;
  text-align: end;
  font-family: 'Panchang', sans-serif;
  font-size: 12px;
}

.element-k {
  height: 100%;
  position: absolute;
  right: -125px;
  top: 20px;
  text-transform: uppercase;
  text-align: start;
  font-family: 'Panchang', sans-serif;
  font-size: 12px;
}


.project-container {
  width: 50vw;
  height: 95vh;
  background-color: rgb(212, 212, 212);
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-decoration: none;
  transition: ease-in-out 0.2s;
  border-radius: 10px;
}


.project-container:hover {
  background-color: rgb(219, 218, 218);
}

.project-container img,
.project-container video {
  height: 60%;
  width: 60%;
  border-radius: 10px;
}


.project-container video {
  object-fit: cover;
}

.project-content {
  color: #000 ;
   height: 100%;
   width: 70%;
   display: flex;
   flex-direction: column;
   position: relative;
}



.explore-btn {
  text-transform: uppercase;
  color: #121212;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  border-bottom: 1px solid #000;
  display: none;
 }

.project-content h1,
.project-content p {
  width: 80% !important;
}

.number {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 25px;
  color: #000;
}


.project-bottom {
  height: 40%;
  display: flex;
  justify-content: end;

}


/************----------------************/
/***************RECENT WORK CSS************/
/************---------------- ************/


.falling-text {
  font-family: 'Teko',sans-serif;
  font-size: 50px;
  text-transform: uppercase;
}


.services-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 40px 25px;

}

.services-right{
  width: 40%;
}

.link-desc{
  font-family: 'Teko', sans-serif;

  list-style: none !important;
}


.services-link {
  text-decoration: none;
}


.link > div {
display: flex;
flex-direction: column;
}


 .link-desc {
  display: inline-block;
  font-size: 10px;
  color: #a5a5a5;
  margin: 0px;
  z-index: 10;
  mix-blend-mode: difference;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-family: 'Panchang', sans-serif;
}



.left-services{
  width: 40%;
  padding-top: 30px;
}
.left-services h1 {
  font-family: 'Panchang', sans-serif;
  font-size: 25px;
}

.left-services p {
    font-weight: 200;
  font-family: 'Oswald', sans-serif;
  font-size: 22px;
}



a, a:visited, a:hover {
  text-decoration: none;
}

li {
  list-style: none;
  text-decoration: none;
}

.nav {
  height: max-content;
  overflow: hidden;
  display: grid;
  place-items: center;
  position: relative;
}

.nav ul {
  width: 100%;
  padding-top: 0px;
  padding-bottom: 65px;
  padding-top: 50px;
}


.nav ul li {
  height: 80px;
  position: relative;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  z-index: 10;
  border-bottom: 1px solid var(--color-border);
}

.services-link {
  width: 100%;
}

.nav ul li::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(252, 250, 250, 0.74); /* Less bright border */
  transition: background 0.3s ease, transform 0.3s ease;
  transform-origin: left;
  transform: scaleX(0);
}

.nav ul li:hover::after {
  background: var(--color-background); /* Brighter on hover */
  transform: scaleX(1); /* Animate from left to right */
}

.nav ul li .link {
  display: flex;
  padding: 1rem 0 1rem 0;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
}

.nav ul li .link .link-title {
  display: inline-block;
  font-weight: 500;
  z-index: 10;
  mix-blend-mode: difference;
  display: inline-block;
  position: relative;
  color: var(--color-white);
  font-size: 15px;
  text-transform: uppercase;
  font-family: 'Panchang', sans-serif;
}

.nav ul li .link > img {
  mix-blend-mode: difference;
  z-index: 10;
}

.hover-reveal {
  position: absolute;
  width: 220px;
  height: 150px;
  top: 90%;
  left: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  overflow: hidden;
  opacity: 0;
  transition: all .5s ease-out;
  z-index: 22;
  object-fit: contain;
}

.hover-reveal img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  transition: transform .4s ease-out;
}



.arrow-icon{
  color: var(--color-white);
}

@media (max-width: 768px) {
 .nav ul {
    width: 93%;
  }
  .nav ul li .link span {
    font-size: 2.5rem;
  }
  .nav ul li .link > img {
    height: 18px;
  }
  .hover-reveal {
    width: 220px;
    height: 300px;
  }
}



/************----------------************/
/***************PARALLAX CONTAINER CSS************/
/************---------------- ************/
 
.parallax-wrapper{
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}


.parallax-container, .parallax-container1 {
  height: 215px;
  width: 100%;
  display: flex;
  gap: 20px;
  overflow: hidden; 
  padding: 10px;
}


.product-card {
  height: 100%;
  width: 30%;

}

.parallax-container1 {
  flex-direction: row-reverse;
}

.parallax-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.parallax-img-container{
  position: relative;
  width: 100%;
  height: 100%;

}


.circleContainer {
  position: relative;
  
}

.circle {
  height: 1550%;
  width: 120%;
  left: -10%;
  border-radius: 0 0 50% 50%;
    background-color: var(--color-background) !important;
  z-index: 1;
  position: absolute;
  box-shadow: 0px 60px 50px rgba(0, 0, 0, 0.748);
}




/************----------------************/
/***************FOOTER CSS************/
/************---------------- ************/



.footer >div{
  padding: 0px 25px;
}

.footer {
  padding: 30px 0 10px 0;
  background-image: url("./assets/img/footer-bg-1.png");
  background-position: center center;
  background-size: cover;
  background-color: #000;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
}



.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.ribbon-element {

  width: 100%;
}

.ribbon-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ribbon-container p {
  margin: 0px;
}


.middle-footer-element {
 text-align: center;
 
}
.middle-footer-element h1 {
  font-size: 90px;
  font-family: 'Panchang', sans-serif;
  font-weight: 700;
}

.email-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.last-footer-element {

  display: flex;
  width: 100%;
  justify-content: space-between;
}

.footer-element {
  display: flex;
  width: 100%;
  justify-content: space-between;
}





/************----------------************/
/***************MEDIA SCREEN CSS************/
/************---------------- ************/
  

@media screen and (max-width: 784px){


 
  .header-container {
    padding: 0px 10px;
    width: 100%;
    justify-content: space-between !important;
    align-items: center;
  }

  .logo-container-x {
    width: 100px !important;
    justify-content: start;
    display: flex;
    margin: 0px !important;
  }

  .mood-container {
    justify-content: center;
    display: flex;
    flex-grow: 1 !important;
    height: 35px !important;
  }

  .contact-element {
    display: flex;
    width: 90px;
    justify-content: end;
  }


  .logo {
    font-size: 10px;
  }

  .contact-element b {
    font-size: 10px;

  }
 
/************----------------************/
/***************LANDING PAGE CSS************/
/************---------------- ************/
  

  .banner {
    padding: 0px 10px !important;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 93vh;
    width: 100%;
  }

  .cover-header {
    position: relative;
    width: 100%;
    top: 23%;
  }


  .cover-center h1 {
    font-size: 20px !important;
 
  }

  .element-r{
    display: none;
  }

  .banner-bottom {
    flex-direction: column-reverse;
    gap: 60px;
    width: 100%;
    position: relative;
 
  }

  .social-links-container {
    width: 100%;
  }


.name-wrapper {
  width: 100%;
  display: flex;
  justify-content: end;
}

.name-container {
  width: 60%;
 display: flex;
}

.name-container div {
  width: 80%;
  
}

  .name-container img {
    height: 200px;
    width: 100%;
  }



  .last-banner {
    display: none;
  }

/************----------------************/
/***************ABOUT SECTION CSS************/
/************---------------- ************/
  

   .last-banner-2 b {
    font-size: 25px;
   }


   .last-banner-2 p {
    font-size: 16px !important;
    color: var(--color-white);

   }

   .about-desc-1 h1 span {
    font-size: 13px !important;
   }

.about-desc-1  span,
.about-element span {
 
  color: var(--color-white);
   font-size: 16px;
  }
  



.about-container {
  padding: 50px 10px;
  gap: 30px;
  height: max-content;
}

.last-banner-2 {
  display: flex;
}

.last-banner-2 p {
 font-size: 22px;
 margin-top: 20px;
}


.about-desc-1 p {
  font-size: 22px;
}

.about-desc-2 {
  flex-direction: column-reverse;
  gap: 30px;
}

.about-element {
  width: 100%;
}

.services-element {
  width: 100%;
}

.services-element ul li {
  width: 90%;
}



/************----------------************/
/***************PROJECTS CSS************/
/************---------------- ************/
   

.horizontal-wrapper {
  height: max-content;
  width: 100%;
  position: relative;
}

.horizontal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 490vh;
  padding: 10px;
  width: 100%;
  justify-content: center;

}

.projects-header-container {
  justify-content: center;
}



 #section-1 {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center ;
  align-items: center;
  padding: 0px;
  height: 100vh;
  
 }


 #section-2 {
  height: 320vh;
  padding: 0;
  width: 100%;
  gap: 10px;
  z-index: 1; 
  background-color: var(--color-background);
 }


 .project-container {
  width: 100%;
  height: 80vh;
  gap: 35px;
 }


 .project-container img ,
 .project-container video{
  width: 75%;
  height: 52%;
 }

 
 .video-container{
  width: 75%;
  height: 52%;
  
 }

 .video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
 }

 .project-content {
  width: 100%;
  position: relative;

 }


 .explore-btn {
  display: flex;
  text-transform: uppercase;
  color: #121212;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  border-bottom: 1px solid #000;
 }

.element-x {
  font-size: 25px !important;
}

.element-2 b {
  font-size: 13px;
}

.projects-header-container h1 {
  font-size: 40px !important;

}


.projects-header {
  position: relative;
  top: -10%;
  
}

.projects-header span {
  display: none;
}


#section-2 {
  flex-direction: column;
}
 



/************----------------************/
/***************PARALLAX CSS************/
/************---------------- ************/
  
.parallax-wrapper {
  height: 300px;
}



.parallax-container1 {
  padding: 5px;
  height: 150px;
}

.parallax-container{
  height: 150px;
}

.product-card {
  width: 150px;
  height: 100%;
}


.circle {
  height: 1000%;
  width: 100%;
  left: 0;
}


/************----------------************/
/***************RECENT WORK CSS************/
/************---------------- ************/
  

.hidden-img{
  display: none;
}



.services-container {
  padding: 30px 10px;
  flex-direction: column;
}

.left-services {
  width: 100%;
}

.left-services h1 {
  font-size: 24px;
}

.left-services p {
  font-size: 16px;
}

.services-right {
  width: 100%;
}



.services-right .nav {
  padding: 0px;
}



.nav ul {
  width: 100%;
  padding: 0px;
}


.link-title {
  font-size: 12px !important;
}

.link-desc {
  font-size: 9px !important;
}


/************----------------************/
/***************FOOTER CSS************/
/************---------------- ************/
  
.footer {
  padding: 50px 10px 20px;

}

.ribbon-element {
  padding: 0px !important;
}


.middle-footer-element h1 {
  font-size: 43px;
}


.last-footer-element {
  justify-content: center;
}

.footer-element {
  flex-direction: column-reverse ;
}


.copyright-container {
  text-align: center;
}



}

